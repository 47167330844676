const en = {
    index: {
        install: 'install',
        name: "BNEX",
        webTitle: 'Please use your mobile phone to scan the QR code to download',
        webTitle2:'Please open in Safari browser',
        download: 'download',
        ratings: 'ratings',
        age: 'age',
        complaint: 'Complaint',
        newFunction: 'What\'s New',
        version: 'Version',
        introduction: 'Application Introduction',
        rates: 'Ratings and Reviews',
        outof: 'Out of 5',
        information: 'Information',
        seller: 'Seller',
        size: 'Size',
        compatibility: 'Compatibility',
        languages: 'Languages',
        ageRating: 'Age Rating',
        price: 'Price',
        free: 'Free',
        age18: '18+',
        langs: 'Chinese & English',
        ios: 'IOS 9.0+',
        appPrivacy: 'App Privacy',
        disclaim: 'Disclaimer: This website only provides developers with App download and installation hosting. The content and operation-related matters in the App are the responsibility of the App developer and have nothing to do with this website.',
        app: {
            info: 'Welcome to BNEX Global, one of the world’s leading digital asset exchanges! Trade over 1100 cryptocurrencies including Bitcoin(BTC), Ethereum(ETH), Dogecoin(DOGE), MX Token(MX), etc.. As a World Leading Crypto exchange, BNEX is known for being the first to launch quality projects and meme coins, BNEX Global provides professional and secure digital assets trading and management services trusted by over 7 million users worldwide.',
            newf: 'This release includes bug fixes and performance improvements.'
        },
        company: 'BNEX Pro',
        copyright: '© 2021 BNEX Global Ltd.',
        copyLink:  'Copy',
        copySuccess:'Copy Success',
        systemTip: 'Due to 17 system limitations, the description file has been downloaded. Please manually go to [Settings]-[General]-[Device Management] and click Install',
        steps:['Step 1: Click [Download]','Step 2: Click [Allow] to open the configuration description file','Step 3: Click [Close]','Step 4: Click [Allow] to open another configuration description file','Step 5: Click [Install] to install the app','Step 6: Go back to [Desktop], click the icon to open the application']

    }


}


export default en;
