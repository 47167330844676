const zh = {
    index: {
        install: '安裝',
        name: "BNEX",
        webTitle: '請使用手機掃描二維碼下載',
        webTitle2:'請在Safari瀏覽器中打開',
        download: '下載',
        ratings: '評分',
        age: '年齡',
        complaint: '應用投訴',
        newFunction: '新功能',
        version: '版本',
        introduction: '應用介紹',
        rates: '評論及評分',
        outof: '滿分5分',
        information: '信息',
        seller: '供應商',
        size: '大小',
        compatibility: '兼容性',
        languages: '語言',
        ageRating: '年齡分級',
        price: '價格',
        free: '免費',
        age18: '限18歲以上',
        langs: '中文和英文',
        ios: '需要IOS 9.0或更高版本',
        appPrivacy: '隱私政策',
        disclaim: '免責聲明: 本網站僅為開發者提供App的下載和安裝托管，App內的內容和運營相關事項由App開發者負責，與本網站無關。',
        app: {
            info: '歡迎來到 BNEX Global，世界領先的數字資產交易所之一！交易超過1100種加密貨幣，包括比特幣(BTC)、以太坊(ETH)、狗狗幣(DOGE)、MX Token(MX)等。 作為世界領先的加密貨幣交易所，BNEX以率先推出優質項目和模因幣而聞名, BNEX Global 提供專業、安全的數字資產交易和管理服務，受到全球超過 700 萬用戶的信賴。',
            newf: '此版本包括錯誤修復和性能改進'

        },
        company: 'BNEX',
        copyright: '© 2022 BNEX Global Ltd.',
        copyLink:  '復制',
        copySuccess:'復制成功',
        systemTip:'由於17系統限制，描述文件已下載，請手動去【設定】-【一般】-【VPN與裝置管理】，點擊安裝',
        steps:['第一步：點擊【下載】','第二步：點擊【允許】，打開配置描述文件','第三步：點擊【關閉】','第四步：點擊【允許】，打開另一個配置描述文件','第五步：點擊【安裝】，安裝app','第六步：回到【桌面】，點擊圖標打開應用']

    }


}

export default zh;
